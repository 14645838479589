import { template as template_c357bdbf8f5e4ddeb41e4dc10b392f69 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c357bdbf8f5e4ddeb41e4dc10b392f69(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
