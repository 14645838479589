import { template as template_ad178209b1b8449ead2ae1b22312bd14 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ad178209b1b8449ead2ae1b22312bd14(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
