import { template as template_6b04e629efcc4f4a8717b1e662af404f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_6b04e629efcc4f4a8717b1e662af404f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
