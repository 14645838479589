import { template as template_2df306fc3b3b4553af2a8b62ee0ee3f2 } from "@ember/template-compiler";
const WelcomeHeader = template_2df306fc3b3b4553af2a8b62ee0ee3f2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
